*{
  margin: 0;
  padding: 0;
}
h1 {
  color: white;
  margin-bottom: 50px;
}

body {
  font-family:'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

